(function ($) {
    $(document).ready(_initEvents);

    function _initEvents() {
        const form = $('form.login'),
            mandatoryFields = form.find('input.required'),
            errorMessage = form.find('.error-message')
        ;
        form.on('submit', function(e) {
            let error = 0;
            if (errorMessage) {
                errorMessage.remove();
            }
            mandatoryFields.each(function() {
                const input = $(this);
                if (input.val() == '') {
                    input.parent().after('<p class="error-message">This field is required</p>');
                    input.closest('.field').addClass('error');
                    error++;
                }
            });
            if (error > 0) {
                e.preventDefault();
            }
        });
    }
})(jQuery);
