let inputs = document.querySelectorAll('.login-field__input');

const focus = (input) => {
    input.parentElement.classList.add('focused')
};

const unfocus = (input) => {
    input.parentElement.classList.remove('focused')
};

for(let input of inputs) {
    input.value !== '' && focus(input);
    input.addEventListener('focus', (e) => focus(e.target));
    input.addEventListener('blur', (e) => e.target.value === '' && unfocus(e.target));
}